import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
// import NextEvents from "./pages/NextEvents";
// import Courses from "./pages/Courses";
import Loader from "./components/Loader";

const LastEvents = React.lazy(() => import("./pages/LastEvents"));
const Partners = React.lazy(() => import("./pages/Partners"));

class App extends Component {
    render() {
        return (
            <div className="page-wrapper mx-auto">
                <Router>
                    <Header />
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route path="/contato" element={<Contact />} />
                            <Route path="/sobre" element={<About />} />
                            {/* <Route path="/next-events" element={<NextEvents />} /> */}
                            {/* <Route path="/cursos" element={<Courses />} /> */}
                            <Route path="/parceiros" element={<Partners />} />
                            <Route path="/eventos" element={<LastEvents />} />
                        </Routes>
                    </Suspense>
                    <Footer />
                </Router>
            </div>
        );
    }
}

export default App;
