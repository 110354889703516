import React, { Component } from "react";
import { EventCard } from "../components/EventCard";
import Loader from "../components/Loader";
import floripa from "../assets/images/floripa.jpeg";
import sjrp from "../assets/images/sjrp.jpeg";
class Home extends Component {
    state = {
        imagesLoaded: false,
    };

    handleImageLoad = () => {
        this.setState({ imagesLoaded: true });
    };

    componentDidMount() {
        const images = [floripa, sjrp];
        let loadedImagesCount = 0;

        images.forEach(src => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImagesCount += 1;
                if (loadedImagesCount === images.length) {
                    this.handleImageLoad();
                }
            };
        });
    }

    render() {
        return (
            <div className="d-flex page-container">
                <div className="d-flex flex-column pb-3">
                    <h1 className="page-title p-3 fs-4">Nossos Eventos</h1>
                    {!this.state.imagesLoaded ? (
                        <Loader />
                    ) : (
                        <div className="container mb-3">
                            <div className="d-flex flex-column aling-items-center">
                                <EventCard
                                    image={sjrp}
                                    url="https://forms.gle/fd19HWhEvS2gd9EA8"
                                />
                            </div>
                            <hr className="hr" />
                            <div className="d-flex flex-column aling-items-center">
                                <EventCard
                                    image={floripa}
                                    url="https://forms.gle/Mf6XbjMr2CCLwPVT9"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Home;
